import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { plugnotasStore, plugnotasConfiguracaoStore, userToolStore, userActionStore, mainStore, } from '@/utils/store-accessor';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import PixIntegradorPagamentoDialog from '@/components/PixIntegradorPagamentoDialog.vue';
import ShowPopup from './ShowPopup.vue';
import SearchCityComponent from '@/components/SearchCityComponent.vue';
import PlugnotasBoasVindasComponent from '@/components/PlugnotasBoasVindasComponent.vue';
import PlugNotasConfiguracaoCertificadoComponent from '@/components/PlugNotasConfiguracaoCertificadoComponent.vue';
import PlugNotasNFCE from '@/views/main/notaFiscal/PlugNotasNFCE.vue';
import PlugNotasNFSE from '@/views/main/notaFiscal/PlugNotasNFSE.vue';
import PlugNotasNFE from '@/views/main/notaFiscal/PlugNotasNFE.vue';
let PlugnotasConfiguracao = class PlugnotasConfiguracao extends Vue {
    constructor() {
        super(...arguments);
        this.tab = 'tab_dados_gerais';
        this.entityObject = {};
        this.loading = false;
        this.loadingSave = false;
        this.data = null;
        this.nfseItems = [];
        this.propPlugnotasId = 0;
        this.plugnotasConfiguracao = null;
        this.cidadeSelecionada = {};
        this.nfseServicosCadastradosItems = [];
        this.isLoadingCarregaDadosEmpresa = true;
        this.historicoRecargaHeaders = [
            { text: 'Valor', value: 'valor_pago' },
            { text: 'Data da compra', value: 'data_pagamento' },
        ];
        this.historicoRecargaItems = [];
        this.creditoHeaders = [
            { text: 'Valor', value: 'valor' },
            { text: 'Quantidade de NF que você poderá emitir', value: 'quantidade' },
            { text: '', value: 'actions' },
        ];
        this.creditoItems = [
            { valor: 50, quantidade: 100 },
            { valor: 100, quantidade: 200 },
            { valor: 200, quantidade: 400 },
            { valor: 500, quantidade: 1000 },
        ];
    }
    get ambientes() {
        return ['Homologação', 'Produção'];
    }
    get adquirirCreditosAllow() {
        return userActionStore.adquirirCreditosNotaFiscal;
    }
    get tiposLogradouro() {
        return [
            'ALAMEDA',
            'AVENIDA',
            'CHÁCARA',
            'COLÔNIA',
            'CONDOMÍNIO',
            'EQNP',
            'ESTÂNCIA',
            'ESTRADA',
            'FAZENDA',
            'PRAÇA',
            'PROLONGAMENTO',
            'RODOVIA',
            'RUA',
            'SÍTIO',
            'TRAVESSA',
            'VICINAL',
        ];
    }
    async creditoAdquirir(item) {
        const financeiroParcela = await plugnotasStore.plugnotasCreditoAdquirir({
            unidadeId: this.propCompanyId,
            valor: item.valor,
        });
        const form = this.$refs.PixIntegradorPagamentoDialog;
        form.show(financeiroParcela);
    }
    async carregaDadosEmpresa() {
        this.isLoadingCarregaDadosEmpresa = true;
        this.data = await plugnotasStore.plugnotasEmpresaConsulta(this.propCompanyId);
        if (this.data) {
            if (!this.data.nfce.config.sefaz) {
                this.data.nfce = {
                    ...this.data.nfce,
                    config: {
                        ...this.data.nfce.config,
                        sefaz: {
                            codigoSegurancaContribuinte: '',
                            idCodigoSegurancaContribuinte: '',
                        },
                    },
                };
            }
            this.cidadeSelecionada = {
                codigo_municipio_completo: this.data.endereco.codigoCidade,
                name: `${this.data.endereco.descricaoCidade} - ${this.data.endereco.estado}`,
                nome_municipio: this.data.endereco.descricaoCidade,
                nome_uf: this.data.endereco.estado,
            };
            if (!this.data.nfse.config.enviarNotificacaoProcessamento) {
                this.data.nfse = {
                    ...this.data.nfse,
                    config: {
                        ...this.data.nfse.config,
                        enviarNotificacaoProcessamento: {
                            destinatarios: [],
                            email: false,
                            webhook: false,
                        },
                    },
                };
            }
        }
        this.isLoadingCarregaDadosEmpresa = false;
    }
    verificaCampoObrigatorios() {
        const errors = [];
        if (!this.data.razaoSocial) {
            errors.push('Razão Social é obrigatório.');
        }
        if (!this.data.simplesNacional) {
            errors.push('Simples Nacional é obrigatório.');
        }
        if (!this.data.regimeTributario) {
            errors.push('Regime Tributário é obrigatório.');
        }
        if (!this.data.inscricaoEstadual) {
            errors.push('Inscrição estadual.');
        }
        if (!this.data.inscricaoMunicipal) {
            errors.push('Inscrição municipal.');
        }
        if (!this.data.email) {
            errors.push('Email é obrigatório.');
        }
        if (!this.data.endereco) {
            errors.push('Dados do endereço é obrigatório.');
        }
        if (!this.data.endereco.bairro) {
            errors.push('Bairro é obrigatório.');
        }
        if (!this.data.endereco.cep) {
            errors.push('CEP é obrigatório.');
        }
        if (!this.data.endereco.estado) {
            errors.push('Estado é obrigatório.');
        }
        if (!this.data.endereco.tipoLogradouro) {
            errors.push('Tipo Logradouro é obrigatório.');
        }
        if (!this.data.endereco.logradouro) {
            errors.push('Logradouro é obrigatório.');
        }
        if (!this.data.endereco.numero) {
            errors.push('Número é obrigatório.');
        }
        if (this.data.nfse.ativo) {
            if (!this.data.nfse.config.rps.numeracao.length) {
                errors.push('(NFSE) Série e Númeração Inicial é obrigatório.');
            }
        }
        if (this.data.nfce.ativo) {
            if (!this.data.nfce.config.sefaz.idCodigoSegurancaContribuinte) {
                errors.push('(NFCE) Número de identificação CSC é obrigatório.');
            }
            if (!this.data.nfce.config.sefaz.codigoSegurancaContribuinte) {
                errors.push('(NFCE) Código do CSC é obrigatório.');
            }
            if (!this.data.nfce.config.numeracao.length) {
                errors.push('Série e Númeração Inicial é obrigatório.');
            }
        }
        mainStore.addErrorToasted({
            // @ts-ignore
            toasted: this.$toasted,
            errors,
        });
        if (errors.length > 0) {
            return false;
        }
        return true;
    }
    async handleSalvar() {
        if (!this.verificaCampoObrigatorios()) {
            return;
        }
        if (this.data.id) {
            this.loadingSave = true;
            const plugnotasUpdate = {
                id: this.data.id,
                cpfCnpj: this.data.cpfCnpj.replace(/\D/g, ''),
                nomeFantasia: this.data.nomeFantasia,
                razaoSocial: this.data.razaoSocial,
                inscricaoEstadual: this.data.inscricaoEstadual,
                inscricaoMunicipal: this.data.inscricaoMunicipal,
                regimeTributario: this.data.regimeTributario,
                regimeTributarioEspecial: this.data.regimeTributarioEspecial,
                incentivoFiscal: this.data.incentivoFiscal,
                incentivadorCultural: this.data.incentivadorCultural,
                simplesNacional: this.data.simplesNacional,
                email: this.data.email,
                certificado: this.data.certificado || '',
                endereco: {
                    bairro: this.data.endereco.bairro,
                    cep: this.data.endereco.cep,
                    codigoCidade: this.data.endereco.codigoCidade,
                    complemento: this.data.endereco.complemento,
                    descricaoCidade: this.data.endereco.descricaoCidade,
                    descricaoPais: 'Brasil',
                    estado: this.data.endereco.estado,
                    logradouro: this.data.endereco.logradouro,
                    numero: this.data.endereco.numero,
                    tipoBairro: this.data.endereco.bairro,
                    tipoLogradouro: this.data.endereco.tipoLogradouro,
                },
                telefone: {
                    ddd: this.data.telefone.ddd,
                    numero: this.data.telefone.numero,
                },
                nfse: {
                    ativo: this.data.nfse.ativo,
                    tipoContrato: 0,
                    config: {
                        calculoAutomaticoIbpt: this.data.nfse.config.calculoAutomaticoIbpt,
                        email: this.data.nfse.config.email,
                        prefeitura: this.data.nfse.config.prefeitura,
                        producao: this.data.nfse.config.producao,
                        rps: { ...this.data.nfse.config.rps },
                    },
                },
                nfe: {
                    ativo: this.data.nfe.ativo,
                    tipoContrato: 0,
                    config: {
                        producao: this.data.nfe.config.producao,
                        numeracao: this.data.nfe.config.numeracao.map((item) => ({
                            numero: item.numero,
                            serie: item.serie,
                        })),
                    },
                },
                nfce: this.data.nfce,
                mdfe: {
                    ativo: false,
                    tipoContrato: 0,
                },
            };
            await plugnotasStore.atualizarDadosCadastroAPiPlugNotas(plugnotasUpdate);
            this.loadingSave = false;
        }
    }
    get allowCreateConfig() {
        if (userToolStore.userToolsIndexed[`Configuração`]) {
            const configuracoes = userToolStore.userToolsIndexed[`Configuração`];
            return configuracoes.find((item) => item.group === 'Nota Fiscal')
                .allow_create;
        }
    }
    get allowEditConfig() {
        if (userToolStore.userToolsIndexed[`Configuração`]) {
            const configuracoes = userToolStore.userToolsIndexed[`Configuração`];
            return configuracoes.find((item) => item.group === 'Nota Fiscal')
                .allow_edit;
        }
    }
    async onConfirmBoasVindas(plugnotasConfiguracao = null) {
        this.plugnotasConfiguracao = plugnotasConfiguracao;
    }
    async mounted() {
        this.loading = true;
        await this.carregaDadosEmpresa();
        this.plugnotasConfiguracao =
            await plugnotasConfiguracaoStore.getPlugnotasConfiguracaoMe();
        this.historicoRecargaItems =
            await plugnotasStore.plugnotasCreditoHistoricoAquisicao();
        this.loading = false;
    }
    onChangeCidade(val) {
        if (!val.hasOwnProperty('nome_uf')) {
            return;
        }
        this.data.endereco.estado = val.nome_uf;
    }
};
__decorate([
    Prop({ type: Number })
], PlugnotasConfiguracao.prototype, "propCompanyId", void 0);
__decorate([
    Watch('cidadeSelecionada', { immediate: true })
], PlugnotasConfiguracao.prototype, "onChangeCidade", null);
PlugnotasConfiguracao = __decorate([
    Component({
        components: {
            PeriodSelectorComponent,
            ShowPopup,
            PlugnotasBoasVindasComponent,
            PixIntegradorPagamentoDialog,
            SearchCityComponent,
            PlugNotasConfiguracaoCertificadoComponent,
            PlugNotasNFCE,
            PlugNotasNFSE,
            PlugNotasNFE,
        },
    })
], PlugnotasConfiguracao);
export default PlugnotasConfiguracao;
