import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { userToolStore } from '@/store';
let PlugNotasNFE = class PlugNotasNFE extends Vue {
    constructor() {
        super(...arguments);
        this.dialogNumeracao = false;
        this.numeracaoSerie = '';
        this.numeracaoInicial = '';
        this.numeracaoIndex = 0;
        this.headerNumeracao = [
            { text: 'Série', value: 'serie' },
            { text: 'Número', value: 'numero' },
            { text: 'Ação', value: 'actions' },
        ];
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed[`NFe`]) {
            return userToolStore.userToolsIndexed[`NFe`][0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed[`NFe`]) {
            return userToolStore.userToolsIndexed[`NFe`][0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed[`NFe`]) {
            return userToolStore.userToolsIndexed[`NFe`][0].allow_delete;
        }
    }
    get plugNotasNFE() {
        this.propsPlugNotasNFE.config.producao = true;
        return this.propsPlugNotasNFE;
    }
    handleSalvarNumeracao() {
        // Adicionar
        if (this.numeracaoIndex === 0) {
            this.propsPlugNotasNFE.config.numeracao.push({
                numero: parseInt(this.numeracaoInicial, 0),
                serie: parseInt(this.numeracaoSerie, 0),
            });
            this.dialogNumeracao = false;
            // Editar
        }
        else if (this.numeracaoIndex > 0) {
            const indexToUpdate = this.numeracaoIndex - 1;
            // $set, é pra alterar na tabela automaticamente.
            this.$set(this.propsPlugNotasNFE.config.numeracao, indexToUpdate, {
                numero: parseInt(this.numeracaoInicial, 0),
                serie: parseInt(this.numeracaoSerie, 0),
            });
            this.dialogNumeracao = false;
        }
    }
    handleAdicionarNumeracaoSerie() {
        this.numeracaoSerie = '';
        this.numeracaoInicial = '';
        this.numeracaoIndex = 0;
        this.dialogNumeracao = true;
    }
    handleEditNumeracao(item, index) {
        this.numeracaoSerie = item.serie;
        this.numeracaoInicial = item.numero;
        this.numeracaoIndex = index + 1;
        this.dialogNumeracao = true;
    }
    handleDeleteNumeracao(index) {
        this.$swal({
            title: 'Deseja realmente excluir?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.propsPlugNotasNFE.config.numeracao.splice(index, 1);
            }
        });
    }
};
__decorate([
    Prop({ type: Object, required: false, default: {} })
], PlugNotasNFE.prototype, "propsPlugNotasNFE", void 0);
PlugNotasNFE = __decorate([
    Component({
        components: {},
    })
], PlugNotasNFE);
export default PlugNotasNFE;
