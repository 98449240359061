import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { plugnotasConfiguracaoStore, plugnotasStore } from '@/utils/store-accessor';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
let PlugnotasBoasVindasComponent = class PlugnotasBoasVindasComponent extends Vue {
    constructor() {
        super(...arguments);
        this.regimeTributario = null;
        this.simplesNacional = null;
        this.temIntegracao = false;
        this.loading = false;
    }
    async onConfirmar() {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
            return;
        }
        const plugnotasConfiguracao = await plugnotasConfiguracaoStore.plugNotasConfiguracaoAceite({
            regimeTributario: this.regimeTributario,
            simplesNacional: this.simplesNacional === 'Sim',
        });
        if (plugnotasConfiguracao) {
            window.location.reload();
        }
        this.$emit('on-validate', plugnotasConfiguracao);
    }
    async mounted() {
        this.loading = true;
        this.temIntegracao = await plugnotasStore.plugnotasVerificaIntegracao();
        this.loading = false;
    }
};
PlugnotasBoasVindasComponent = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
        },
    })
], PlugnotasBoasVindasComponent);
export default PlugnotasBoasVindasComponent;
