import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { plugnotasNfseServicoStore, } from '@/utils/store-accessor';
let Create = class Create extends Vue {
    constructor() {
        super(...arguments);
        this.tab = '';
        this.isSaving = false;
        this.deducaoTipoSelected = {
            id: 0,
            label: '',
        };
        this.plugNotasNfseServicoResponse = {
            id: 0,
            codigo: '',
            discriminacao: '',
            codigo_tributacao: '',
            cnae: '',
            iss_tipo_tributacao: 0,
            iss_exigibilidade: 0,
            iss_retido: false,
            iss_aliquota: 0,
            iss_valor: 0,
            iss_valor_retido: 0,
            iss_processo_suspensao: '',
            deducao_tipo: 0,
            deducao_descricao: '',
            retencao_pis_base_calculo: 0,
            retencao_pis_aliquota: 0,
            retencao_pis_valor: 0,
            retencao_pis_cst: '',
            retencao_cofins_base_calculo: 0,
            retencao_cofins_aliquota: 0,
            retencao_cofins_valor: 0,
            retencao_cofins_cst: '',
            retencao_csll_aliquota: 0,
            retencao_csll_valor: 0,
            retencao_inss_aliquota: 0,
            retencao_inss_valor: 0,
            retencao_irrf_aliquota: 0,
            retencao_irrf_valor: 0,
            retencao_outras_retencoes: 0,
            retencao_cpp_aliquota: 0,
            retencao_cpp_valor: 0,
            tributavel: false,
            ibpt_simplificado_aliquota: 0,
            ibpt_detalhado_aliquota_municipal: 0,
            ibpt_detalhado_aliquota_estadual: 0,
            ibpt_detalhado_aliquota_federal: 0,
            responsavel_retencao: '',
            tributos_federais_retidos: false,
            financeiro_categoria_id: 0,
            gerar_automaticamente_apos_baixa: false,
        };
    }
    get responsaveisRetencao() {
        return ['Tomador', 'Intermediário'];
    }
    get exigibilidades() {
        return [
            { id: 1, label: 'Exigível' },
            { id: 2, label: 'Não Incidência' },
            { id: 3, label: 'Isenção' },
            { id: 4, label: 'Exportação' },
            { id: 5, label: 'Imunidade' },
            { id: 6, label: 'Supenso por Ação Judicial' },
            { id: 7, label: 'Suspenso por Ação Administrativa' },
        ];
    }
    get issTipoTributacoes() {
        return [
            { id: 1, label: 'Isento de ISS' },
            { id: 2, label: 'Imune' },
            { id: 3, label: 'Não Incidência no Município' },
            { id: 4, label: 'Não Tributável' },
            { id: 5, label: 'Retido' },
            { id: 6, label: 'Tributável Dentro do Município' },
            { id: 7, label: 'Tributável Fora do Município' },
            { id: 8, label: 'Tributável Dentro do Município pelo tomador' },
        ];
    }
    get deducaoTipos() {
        return [
            { id: 0, label: 'Sem Deduções' },
            { id: 1, label: 'Materiais' },
            { id: 2, label: 'Subempreitada de Mão de Obra' },
            { id: 3, label: 'Serviços' },
            { id: 4, label: 'Produção Externa' },
            { id: 5, label: 'Alimentação e Bebidas' },
            { id: 6, label: 'Reembolso de Despesas' },
            { id: 7, label: 'Repasse Consorciado' },
            { id: 8, label: 'Repasse Plano de Saúde' },
            { id: 99, label: 'Outras Deduções' },
        ];
    }
    setValorNosCampos() {
        if (this.plugNotasNfseServicoResponse.deducao_tipo) {
            this.deducaoTipoSelected = {
                id: this.plugNotasNfseServicoResponse.deducao_tipo,
                label: this.plugNotasNfseServicoResponse.deducao_descricao,
            };
        }
    }
    async handleSave() {
        this.isSaving = true;
        this.plugNotasNfseServicoResponse.deducao_tipo =
            this.deducaoTipoSelected.id;
        this.plugNotasNfseServicoResponse.deducao_descricao =
            this.deducaoTipoSelected.label;
        if (this.plugNotasNfseServicoResponse.id) {
            const plugNotasNfseServicoUpdate = {
                ...this.plugNotasNfseServicoResponse,
            };
            await plugnotasNfseServicoStore.updatePlugnotasNfseServico(plugNotasNfseServicoUpdate);
        }
        else {
            const plugNotasNfseServicoCreate = {
                ...this.plugNotasNfseServicoResponse,
            };
            await plugnotasNfseServicoStore.createPlugnotasNfseServico(plugNotasNfseServicoCreate);
        }
        this.isSaving = false;
        this.$emit('on-confirm');
    }
    async mounted() {
        if (this.propPlugnotasId) {
            this.plugNotasNfseServicoResponse =
                await plugnotasNfseServicoStore.getPlugnotasNfseServico(this.propPlugnotasId);
        }
        this.setValorNosCampos();
    }
};
__decorate([
    Prop({ type: Number, required: false, default: null })
], Create.prototype, "propPlugnotasId", void 0);
Create = __decorate([
    Component
], Create);
export default Create;
