import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import { mainStore, plugnotasStore, userToolStore } from '@/store';
import NFCEProduto from '@/views/main/plugNotas/NFCEProduto.vue';
let PlugNotasNFCE = class PlugNotasNFCE extends Vue {
    constructor() {
        super(...arguments);
        this.selectPeriodDate = null;
        this.notasNFCEs = null;
        this.isLoadingConsultarNotasEmitidas = false;
        this.showSenha = false;
        this.dialogNumeracao = false;
        this.numeracaoSerie = '';
        this.numeracaoInicial = '';
        this.numeracaoIndex = 0;
        this.headerNumeracao = [
            { text: 'Série', value: 'serie' },
            { text: 'Número', value: 'numero' },
            { text: 'Ação', value: 'actions' },
        ];
        this.nfceEmitidaHeaders = [
            { text: 'Situação', value: 'situacao' },
            { text: 'Quantidade', value: 'quantidade' },
        ];
    }
    emailValid(field) {
        if (!field) {
            return true;
        }
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(field) || 'E-mail inválido';
    }
    get allowView() {
        if (userToolStore.userToolsIndexed[`NFCe`]) {
            return userToolStore.userToolsIndexed[`NFCe`][0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed[`NFCe`]) {
            return userToolStore.userToolsIndexed[`NFCe`][0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed[`NFCe`]) {
            return userToolStore.userToolsIndexed[`NFCe`][0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed[`NFCe`]) {
            return userToolStore.userToolsIndexed[`NFCe`][0].allow_delete;
        }
    }
    get plugNotasNFCE() {
        this.propsPlugNotasNFCE.config.producao = true;
        return this.propsPlugNotasNFCE;
    }
    getCompanyId() {
        return (parseInt(this.$route.params.id, 0) || mainStore.userProfile.company.id);
    }
    handleSalvarNumeracao() {
        // Adicionar
        if (this.numeracaoIndex === 0) {
            this.propsPlugNotasNFCE.config.numeracao.push({
                numero: parseInt(this.numeracaoInicial, 0),
                serie: parseInt(this.numeracaoSerie, 0),
            });
            this.dialogNumeracao = false;
            // Editar
        }
        else if (this.numeracaoIndex > 0) {
            const indexToUpdate = this.numeracaoIndex - 1;
            // $set, é pra alterar na tabela automaticamente.
            this.$set(this.propsPlugNotasNFCE.config.numeracao, indexToUpdate, {
                numero: parseInt(this.numeracaoInicial, 0),
                serie: parseInt(this.numeracaoSerie, 0),
            });
            this.dialogNumeracao = false;
        }
    }
    async plugnotasNfceRelatorioPorCNPJ() {
        this.isLoadingConsultarNotasEmitidas = true;
        this.notasNFCEs = await plugnotasStore.plugnotasRelatorioPorCNPJ({
            tipo_nota: 'nfce',
            company_id: this.getCompanyId(),
            data_inicio: this.selectPeriodDate[0],
            data_fim: this.selectPeriodDate[1],
        });
        this.isLoadingConsultarNotasEmitidas = false;
    }
    handleAdicionarNumeracaoSerie() {
        this.numeracaoSerie = '';
        this.numeracaoInicial = '';
        this.numeracaoIndex = 0;
        this.dialogNumeracao = true;
    }
    handleEditNumeracao(item, index) {
        this.numeracaoSerie = item.serie;
        this.numeracaoInicial = item.numero;
        this.numeracaoIndex = index + 1;
        this.dialogNumeracao = true;
    }
    handleDeleteNumeracao(index) {
        this.$swal({
            title: 'Deseja realmente excluir?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.propsPlugNotasNFCE.config.numeracao.splice(index, 1);
            }
        });
    }
    async integracaoAtiva(val) {
        if (!val) {
            this.plugNotasNFCE.config.integracoes.plugStorage.email = '';
            this.plugNotasNFCE.config.integracoes.plugStorage.senha = '';
            return { ...this.plugNotasNFCE };
        }
    }
};
__decorate([
    Prop({ type: Object, required: false, default: {} })
], PlugNotasNFCE.prototype, "propsPlugNotasNFCE", void 0);
__decorate([
    Watch('plugNotasNFCE.config.integracoes.plugStorage.ativo')
], PlugNotasNFCE.prototype, "integracaoAtiva", null);
PlugNotasNFCE = __decorate([
    Component({
        components: {
            PeriodSelectorComponent,
            NFCEProduto,
        },
    })
], PlugNotasNFCE);
export default PlugNotasNFCE;
