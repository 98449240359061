import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { plugnotasNfceProdutoStore, userToolStore, mainStore, } from '@/utils/store-accessor';
let NFCEProduto = class NFCEProduto extends Vue {
    constructor() {
        super(...arguments);
        this.dialogCadastroProduto = false;
        this.isSaving = false;
        this.gerarAutomaticamente = false;
        this.produtos = [];
        this.produtoSelecionado = null;
        this.plugnotasNfceProdutosHeaders = [
            { text: 'Produto', value: 'financeiro_categoria.nome' },
            {
                text: 'Gerar automaticamente',
                value: 'gerar_automaticamente_apos_baixa',
            },
            { text: '', value: 'actions' },
        ];
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed[`NFCe`]) {
            return userToolStore.userToolsIndexed[`NFCe`][0].allow_create;
        }
    }
    get plugnotasNfceProdutos() {
        return plugnotasNfceProdutoStore.plugnotasNfseProduto;
    }
    async handleSaveProduto() {
        const financeiroCategoriaId = this.produtoSelecionado.id;
        this.isSaving = true;
        if (!this.plugnotasNFCEProdutoId) {
            const obj = {
                financeiro_categoria_id: financeiroCategoriaId,
                gerar_automaticamente_apos_baixa: this.gerarAutomaticamente,
            };
            await plugnotasNfceProdutoStore.plugnotasNfceProdutoCreate(obj);
        }
        else {
            const obj = {
                id: this.plugnotasNFCEProdutoId,
                financeiro_categoria_id: financeiroCategoriaId,
                gerar_automaticamente_apos_baixa: this.gerarAutomaticamente,
            };
            await plugnotasNfceProdutoStore.plugnotasNfceProdutoUpdate(obj);
        }
        this.isSaving = false;
        await this.carregaServico();
    }
    async handleCriarProdutoNFCE() {
        this.plugnotasNFCEProdutoId = undefined;
        this.produtoSelecionado = undefined;
        this.gerarAutomaticamente = false;
        this.dialogCadastroProduto = true;
    }
    async handleEditProdutoNFCE(item) {
        this.plugnotasNFCEProdutoId = item.id;
        this.produtoSelecionado = item.financeiro_categoria;
        this.gerarAutomaticamente = item.gerar_automaticamente_apos_baixa;
        this.dialogCadastroProduto = true;
    }
    async handleDeleteProdutoNFCE(item) {
        this.$swal({
            title: 'Deseja excluir o item?',
            text: 'O item será excluído e não poderá ser recuperado',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await plugnotasNfceProdutoStore.plugnotasNfceProdutoDelete(item.id);
            }
        });
    }
    async carregaServico() {
        await plugnotasNfceProdutoStore.plugnotasNfceProdutosByCompanyId(mainStore.userProfile.company.id);
    }
    async mounted() {
        this.produtos =
            await plugnotasNfceProdutoStore.plugnotasNfceBuscarFinanceiro();
        await this.carregaServico();
    }
};
NFCEProduto = __decorate([
    Component
], NFCEProduto);
export default NFCEProduto;
