import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { mainStore, plugnotasConfiguracaoStore, plugnotasStore } from '@/store';
import ShowPopup from '@/components/ShowPopup.vue';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
Validator.extend('email', {
    getMessage: (field) => `${field} deve ser um email correto!`,
    validate: async (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !!re.test(email);
    },
});
let PlugNotasConfiguracaoCertificadoComponent = class PlugNotasConfiguracaoCertificadoComponent extends Vue {
    constructor() {
        super(...arguments);
        this.arquivo = null;
        this.senha = '';
        this.email = '';
        this.showSenha = false;
        this.isLoadingEnviandoCertificado = false;
        this.isLoading = false;
        this.descricaoCertificado = '';
        this.dataVencimento = '';
    }
    get companyId() {
        return mainStore.userProfile.company.id;
    }
    handleShowCertificado() {
        const refs = this.$refs;
        const form = refs.showCertificado;
        form.show();
    }
    async handleEnviarCertificado() {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
            return;
        }
        this.isLoadingEnviandoCertificado = true;
        if (this.propCertificado) {
            const response = await plugnotasConfiguracaoStore.plugNotasConfiguracaoAtualizaCertificado({
                arquivo: this.arquivo,
                senha: this.senha,
                email: this.email,
                company_id: parseInt(this.$route.params.id, 0) || this.companyId,
                certificadoId: this.propCertificado,
            });
            if (response) {
                await this.$emit('on-confirm');
            }
        }
        else {
            const response = await plugnotasConfiguracaoStore.plugNotasConfiguracaoInserirCertificado({
                arquivo: this.arquivo,
                senha: this.senha,
                email: this.email,
                company_id: parseInt(this.$route.params.id, 0) || this.companyId,
            });
            if (response) {
                await this.$emit('on-confirm');
            }
        }
        this.isLoadingEnviandoCertificado = false;
    }
    async carregaDadosTela(certificadoId) {
        if (certificadoId) {
            this.isLoading = true;
            this.certificadoResponse = await plugnotasStore.plugnotasBuscaCertificado({ certificadoId, companyId: this.companyId });
            this.email = this.certificadoResponse.email;
            this.descricaoCertificado = this.certificadoResponse.nome;
            this.dataVencimento = this.certificadoResponse.vencimento;
            this.isLoading = false;
        }
    }
    async mounted() {
        await this.carregaDadosTela(this.propCertificado);
    }
    async changePropCertificado(newCertificadoId) {
        await this.carregaDadosTela(newCertificadoId);
    }
};
__decorate([
    Prop({ type: String, required: false, default: '' })
], PlugNotasConfiguracaoCertificadoComponent.prototype, "propCertificado", void 0);
__decorate([
    Prop({ type: Boolean })
], PlugNotasConfiguracaoCertificadoComponent.prototype, "propAllow", void 0);
__decorate([
    Watch('propCertificado')
], PlugNotasConfiguracaoCertificadoComponent.prototype, "changePropCertificado", null);
PlugNotasConfiguracaoCertificadoComponent = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
            ShowPopup,
        },
    })
], PlugNotasConfiguracaoCertificadoComponent);
export default PlugNotasConfiguracaoCertificadoComponent;
