import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { mainStore } from '@/store';
import PlugnotasConfiguracao from '@/components/PlugnotasConfiguracao.vue';
let Configuracao = class Configuracao extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.id = null;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    async mounted() {
        this.loading = true;
        this.id = this.userProfile.company.id;
        this.loading = false;
    }
};
Configuracao = __decorate([
    Component({
        components: {
            PlugnotasConfiguracao,
        },
    })
], Configuracao);
export default Configuracao;
