import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let ShowPopup = class ShowPopup extends Vue {
    constructor() {
        super(...arguments);
        this.dialog = false;
    }
    async show() {
        this.dialog = true;
    }
    async close() {
        this.dialog = false;
    }
};
__decorate([
    Prop(String)
], ShowPopup.prototype, "propTitle", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ShowPopup.prototype, "propSmall", void 0);
__decorate([
    Prop({ type: String, default: '70%' })
], ShowPopup.prototype, "propWidth", void 0);
ShowPopup = __decorate([
    Component({
        components: {}
    })
], ShowPopup);
export default ShowPopup;
