import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import PlugnotasNfseServico from '@/views/main/plugNotas/Create.vue';
import { mainStore, plugnotasNfseServicoStore, plugnotasStore, utilsStore, userToolStore, } from '@/store';
import ShowPopup from '@/components/ShowPopup.vue';
import UploadFilesComponent from '@/components/UploadFilesComponent.vue';
let PlugNotasNFSE = class PlugNotasNFSE extends Vue {
    constructor() {
        super(...arguments);
        this.selectPeriodDate = null;
        this.nfseEmitidaItems = null;
        this.dialogRPS = false;
        this.rpsSerie = '';
        this.rpsNumeracaoInicial = 0;
        this.isLoadingExcluir = false;
        this.nfseServicosCadastradosItems = [];
        this.propPlugnotasId = 0;
        this.nfseImportarXml = [];
        this.numeracaoIndex = 0;
        this.nfseServicosCadastradosHeaders = [
            { text: 'Código', value: 'codigo' },
            { text: 'Discriminação', value: 'discriminacao' },
            { text: 'Código tributação', value: 'codigo_tributacao' },
            { text: 'CNAE', value: 'cnae' },
            { text: '', value: 'actions' },
        ];
        this.nfseEmitidaHeaders = [
            { text: 'Situação', value: 'situacao' },
            { text: 'Quantidade', value: 'quantidade' },
        ];
        this.rpsHeaders = [
            { text: 'Série', value: 'serie' },
            { text: 'Número', value: 'numero' },
            { text: 'Numeração atual', value: 'numeracaoAtual' },
            { text: 'Ação', value: 'actions' },
        ];
    }
    get allowView() {
        if (userToolStore.userToolsIndexed[`NFSe`]) {
            return userToolStore.userToolsIndexed[`NFSe`][0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed[`NFSe`]) {
            return userToolStore.userToolsIndexed[`NFSe`][0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed[`NFSe`]) {
            return userToolStore.userToolsIndexed[`NFSe`][0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed[`NFSe`]) {
            return userToolStore.userToolsIndexed[`NFSe`][0].allow_delete;
        }
    }
    get plugNotasNFSE() {
        this.propsPlugNotasNFSE.config.producao = true;
        return this.propsPlugNotasNFSE;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    getCompanyId() {
        return (parseInt(this.$route.params.id, 0) || mainStore.userProfile.company.id);
    }
    async handleNFSeImportarXml() {
        await utilsStore.utilsNFSeImportarXML({
            companyId: this.userProfile.company.id,
            files: this.nfseImportarXml,
        });
    }
    handleDelete({ id }) {
        this.$swal({
            title: 'Deseja realmente excluir?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.isLoadingExcluir = true;
                await plugnotasNfseServicoStore.deletePlugnotasNfseServico(id);
                const servicos = this.nfseServicosCadastradosItems.filter((item) => item.id !== id);
                this.nfseServicosCadastradosItems = servicos;
                this.isLoadingExcluir = false;
            }
        });
    }
    handleEdit(item) {
        this.propPlugnotasId = item.id;
        const form = this.$refs.showRefPlugnotas;
        form.show();
    }
    handleCriar() {
        this.propPlugnotasId = 0;
        const form = this.$refs.showRefPlugnotas;
        form.show();
    }
    handleEditRPS(item, index) {
        this.rpsNumeracaoInicial = item.numero;
        this.rpsSerie = item.serie;
        this.numeracaoIndex = index + 1;
        this.dialogRPS = true;
    }
    handleDeleteRPS(index) {
        this.$swal({
            title: 'Deseja realmente excluir?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.plugNotasNFSE.config.rps.numeracao.splice(index, 1);
            }
        });
    }
    handleSalvarRPS() {
        // Adicionar
        if (this.numeracaoIndex === 0) {
            this.plugNotasNFSE.config.rps.numeracao.push({
                numero: this.rpsNumeracaoInicial,
                serie: this.rpsSerie,
            });
            this.dialogRPS = false;
            // Editar
        }
        else if (this.numeracaoIndex > 0) {
            const indexToUpdate = this.numeracaoIndex - 1;
            // $set, é pra alterar na tabela automaticamente.
            this.$set(this.plugNotasNFSE.config.rps.numeracao, indexToUpdate, {
                numero: this.rpsNumeracaoInicial,
                serie: this.rpsSerie,
            });
            this.dialogRPS = false;
        }
    }
    handleAdicionarRPS() {
        this.rpsNumeracaoInicial = 0;
        this.rpsSerie = '';
        this.numeracaoIndex = 0;
        this.dialogRPS = true;
    }
    async plugnotasRelatorioPorCNPJ() {
        this.nfseEmitidaItems = await plugnotasStore.plugnotasRelatorioPorCNPJ({
            tipo_nota: 'nfse',
            company_id: this.getCompanyId(),
            data_inicio: this.selectPeriodDate[0],
            data_fim: this.selectPeriodDate[1],
        });
    }
    async onConfirm() {
        await this.carregaServicos();
        const form = this.$refs.showRefPlugnotas;
        form.close();
    }
    async carregaServicos() {
        this.nfseServicosCadastradosItems =
            await plugnotasNfseServicoStore.getPlugnotasNfseServicosByCompanyId(this.getCompanyId());
    }
    async mounted() {
        await this.carregaServicos();
    }
};
__decorate([
    Prop({ type: Object, required: false, default: {} })
], PlugNotasNFSE.prototype, "propsPlugNotasNFSE", void 0);
PlugNotasNFSE = __decorate([
    Component({
        components: {
            PeriodSelectorComponent,
            PlugnotasNfseServico,
            ShowPopup,
            UploadFilesComponent,
        },
    })
], PlugNotasNFSE);
export default PlugNotasNFSE;
