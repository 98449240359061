import { __decorate } from "tslib";
import { Component, Emit, Vue, Prop, Watch } from 'vue-property-decorator';
import { cityStore, mainStore } from '@/store';
let SearchCityComponent = class SearchCityComponent extends Vue {
    constructor() {
        super(...arguments);
        this.validatorErrors = [];
        this.loadingCity = false;
        this.searchCity = null;
        this.selectedCity = {};
        this.citiesOptions = [];
        this.city_codigo_municipio_completo = null;
        this.timer = 0;
        this.firstTime = true;
        this.cepCity = {};
    }
    async validateField() {
        const valid = this.value ? Object.keys(this.value).length > 1 : false;
        if (!valid) {
            mainStore.addErrorToasted({
                // @ts-ignore
                toasted: this.$toasted,
                errors: [`${this.propTitle} deve ser preenchido!`]
            });
            this.validatorErrors = [`${this.propTitle} deve ser preenchido!`];
        }
        else {
            this.validatorErrors = [];
        }
        return valid;
    }
    onSelectCity() {
        return this.selectedCity;
    }
    async onPropUpdate(val) {
        this.selectedCity = val;
    }
    async onSearchCity(val) {
        this.onSelectCity();
        return val && val !== this.selectedCity && this.querySelections(val);
    }
    async onChangeCidade(val) {
        if (this.citiesOptions.indexOf(this.value) < 0) {
            if (Object.keys(this.value).length > 1) {
                this.citiesOptions.push(this.value);
            }
        }
        // @ts-ignore
        this.nome_municipio = this.selectedCity.nome_municipio;
        // @ts-ignore
        this.nome_uf = this.selectedCity.nome_uf;
        // @ts-ignore
        this.codigo_municipio_completo =
            // @ts-ignore
            this.selectedCity.codigo_municipio_completo;
        this.$emit('input', val);
    }
    async querySelections(v) {
        this.loadingCity = true;
        // Simulated ajax query
        clearTimeout(this.timer);
        // @ts-ignore
        this.timer = setTimeout(async () => {
            this.citiesOptions = await cityStore.getCityByName(v);
            if (this.citiesOptions.indexOf(this.value) < 0) {
                if (Object.keys(this.value).length > 1) {
                    this.citiesOptions.push(this.value);
                }
            }
            this.loadingCity = false;
        }, 1000);
    }
    beforeMount() {
        if (Object.keys(this.value).length > 1) {
            this.citiesOptions = [this.value];
        }
    }
    async beforeUpdate() {
        if (this.value.nome_municipio && this.firstTime) {
            this.firstTime = false;
        }
    }
};
__decorate([
    Prop()
], SearchCityComponent.prototype, "value", void 0);
__decorate([
    Prop({ default: 'Cidade' })
], SearchCityComponent.prototype, "propTitle", void 0);
__decorate([
    Prop({ default: false })
], SearchCityComponent.prototype, "propDisabled", void 0);
__decorate([
    Prop({ default: false })
], SearchCityComponent.prototype, "propValidator", void 0);
__decorate([
    Emit('onSelectCity')
], SearchCityComponent.prototype, "onSelectCity", null);
__decorate([
    Watch('propItemSelected')
], SearchCityComponent.prototype, "onPropUpdate", null);
__decorate([
    Watch('searchCity')
], SearchCityComponent.prototype, "onSearchCity", null);
__decorate([
    Watch('value')
], SearchCityComponent.prototype, "onChangeCidade", null);
SearchCityComponent = __decorate([
    Component
], SearchCityComponent);
export default SearchCityComponent;
