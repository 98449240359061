var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{staticClass:"ma-1"},[(_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"card"}}):_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[(_vm.descricaoCertificado)?_c('v-text-field',{attrs:{"label":"Certificado","hide-details":"","readonly":""},model:{value:(_vm.descricaoCertificado),callback:function ($$v) {_vm.descricaoCertificado=$$v},expression:"descricaoCertificado"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.dataVencimento)?_c('v-text-field',{attrs:{"label":"Vencimento","hide-details":"","readonly":""},model:{value:(_vm.dataVencimento),callback:function ($$v) {_vm.dataVencimento=$$v},expression:"dataVencimento"}}):_vm._e()],1),_c('v-spacer'),_c('v-col',{staticClass:"text-end",attrs:{"cols":"auto"}},[(_vm.propAllow)?_c('v-btn',{staticClass:"success",on:{"click":_vm.handleShowCertificado}},[_vm._v(" "+_vm._s(_vm.propCertificado ? "Atualizar" : "Adicionar")+" certificado ")]):_vm._e()],1),_c('ShowPopup',{ref:"showCertificado",attrs:{"propTitle":'Certificado',"propSmall":true,"propWidth":"60%"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"debounce":100,"name":"Certificado","vid":"arquivo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"accept":".pfx, .p12","label":"Selecione o certificado","error-messages":errors,"required":""},model:{value:(_vm.arquivo),callback:function ($$v) {_vm.arquivo=$$v},expression:"arquivo"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"debounce":100,"name":"Senha","vid":"senha","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Senha","append-icon":_vm.showSenha ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showSenha ? 'text' : 'password',"error-messages":errors,"required":""},on:{"click:append":function($event){_vm.showSenha = !_vm.showSenha}},model:{value:(_vm.senha),callback:function ($$v) {_vm.senha=$$v},expression:"senha"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"debounce":100,"name":"E-mail","vid":"email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"E-mail","hint":"Email para recebimento de notificações sobre o vencimento do certificado digital","persistent-hint":"","error-messages":errors},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"success",attrs:{"disabled":invalid,"loading":_vm.isLoadingEnviandoCertificado},on:{"click":_vm.handleEnviarCertificado}},[_vm._v(" "+_vm._s(_vm.propCertificado ? ' Atualizar' : 'Adicionar')+" certificado ")])],1)],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }